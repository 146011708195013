<template>
  <b-container>
    <section class="page-section-top">
      <b-row>
        <b-col>
          <div class="heading-div">
            <h1 class="heading-h1">404 Page Not Found</h1>
          </div>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
